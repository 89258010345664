import React, { Component } from 'react';
// import {Link} from 'react-router-dom';
// import { ethers } from 'ethers';
import './CreateSurvey.css';
import { CardSide } from './CardSide';
// import {Link} from 'react-router-dom'
import { Tabs, Tab } from 'react-bootstrap';
import Design from './Design';
import { MapProvider } from '../MapState';
import Preview from './Preview';
import Result from './Result';

type State = {
  key: string;
  address: String;
};
// type handleChange(e: React.ChangeEvent<any>): void;
export class CreateSurvey extends Component<State> {
  state: State = {
    key: 'Design',
    address: '',
  };
  componentDidMount = async () => {};
  handleSelect = (key: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ key });
  };

  // handleClose = async () => {
  //   if (window.ethereum) {
  //     // @ts-ignore
  //     window.ethereum.enable();
  //     const metamaskProvider = new ethers.providers.Web3Provider(window.ethereum);
  //     // const network = await metamaskProvider.getNetwork();
  //     const wallet = await metamaskProvider.getSigner();
  //     // @ts-ignore
  //     window.wallet = wallet;
  //     const Address = await wallet.getAddress();
  //     console.log(wallet);
  //     this.setState({ address: Address });
  //   }
  // };

  // handleChange =( e : any) => this.setState({ [e.target.name]: e.target.value });

  render() {
    return (
      <MapProvider>
        <div className="survey bg-light">
          <div className="row">
            <div className="col-md-6">
              <CardSide address={this.state.address} />
            </div>

            <div className="col-md-6 pinside50 right-sidebar">
              <br />
              <br />
              <br />
              <br />

              <div className="tabs ">
                <Tabs
                  defaultActiveKey="See Results"
                  activeKey={this.state.key}
                  transition={false}
                  id="noanim-tab-example"
                >
                  <Tab eventKey="Design" title="Design Survey">
                    <button
                      type="button"
                      onClick={this.handleSelect('Preview')}
                      className="btn btn-primary float-right"
                    >
                      {' '}
                      preivew
                    </button>
                    <Design changeTab={this.handleSelect} />
                    <button
                      type="button"
                      onClick={this.handleSelect('Preview')}
                      className="btn btn-primary  float-right"
                    >
                      {' '}
                      preview
                    </button>
                  </Tab>
                  <Tab eventKey="Preview" title="Preview Survey">
                    <button
                      type="button"
                      onClick={this.handleSelect('Design')}
                      className="btn btn-primary float-left"
                    >
                      <i className="fa fa-pencil"></i> Edit Survey
                    </button>
                    <button
                      type="button"
                      onClick={this.handleSelect('See Results')}
                      className="btn btn-primary float-right"
                    >
                      {' '}
                      Submit
                    </button>
                    <br />
                    <br />
                    <Preview Next={this.handleSelect} />
                    <button
                      type="button"
                      onClick={this.handleSelect('See Results')}
                      className="btn btn-primary "
                    >
                      {' '}
                      Submit
                    </button>
                    <br />
                    <br />
                  </Tab>
                  <Tab eventKey="See Results" title="See results">
                    <button
                      type="button"
                      onClick={this.handleSelect('Preview')}
                      className="btn btn-primary float-left"
                    >
                      Back
                    </button>

                    <Result address={this.state.address} />
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </MapProvider>
    );
  }
}
