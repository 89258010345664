import React, { Component } from 'react';
// import NewHeader from '../Headers/NewHeader';

type MyProps = {
  // using `interface` is also ok
  address: String;
};

// type handleChange(e: React.ChangeEvent<any>): void;

export class CardSide extends Component<MyProps> {
  // handleChange =( e : any) => this.setState({ [e.target.name]: e.target.value });

  render() {
    return (
      <div className="pinside40">
        <div className="bridge-bgd left-sidebar">
          {/* <NewHeader /><br/><br/> */}
          <div className="topbar-social-flex" style={{ position: 'absolute', bottom: '0' }}>
            <a href="/">
              <img
                className="cert-white-logo"
                src={process.env.PUBLIC_URL + '/images/whitelogo.png'}
                alt="white-logo"
              />
            </a>
            <ul className="social-sm-flex">
              <li>
                <a
                  href="https://www.facebook.com/eraswap"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="social-img"
                    alt="fb"
                    src={process.env.PUBLIC_URL + '/images/fbC.png'}
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/eraswap/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="social-img"
                    alt="ln"
                    src={process.env.PUBLIC_URL + '/images/linkdIn.png'}
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/eraswap/?hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="social-img"
                    alt="in"
                    src={process.env.PUBLIC_URL + '/images/instagram.png'}
                  />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/EraSwapTech" target="_blank" rel="noopener noreferrer">
                  <img
                    className="social-img"
                    alt="tw"
                    src={process.env.PUBLIC_URL + '/images/twitter.png'}
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCGCP4f5DF1W6sbCjS6y3T1g?view_as=subscriber"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="social-img"
                    alt="yt"
                    src={process.env.PUBLIC_URL + '/images/utubeC.png'}
                  />
                </a>
              </li>
            </ul>
          </div>
          <div className="wrapper-container">
            <div className="container text-left">
              <div className="row  align-items-center justify-content-center text-center">
                <div className="col-lg-10 align-self-end">
                  <h1 className="text-uppercase text-white font-weight-bold ">
                    Create a New Survey
                  </h1>
                  <div className="divider my-4"></div>
                </div>
                <div className="col-lg-8 align-self-baseline">
                  <h6 className="mrg-home ">
                    Create a new and unique survey to engage with your Customers or Employees. Your
                    survey statistics and data will be stored securely on Era Swap Decentralized
                    Blockchain Network.
                  </h6>
                </div>
                {this.props.address ? (
                  <p className="text-white font-weight-bold">
                    You are connected with : {this.props.address}
                  </p>
                ) : null}
                <br />
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
