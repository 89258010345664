/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './MySurvey.css';
import { Spinner } from 'react-bootstrap';
import { Timer } from '../Timer';
import { Footer } from '../Footer';
import Swal from 'sweetalert2';
import { ethers } from 'ethers';

type State = {
  bunchModal: boolean;
  allSurvey: any[];
  address: string | null;
  spin: boolean;
  currentIncentive: string;
  newIncentive: string;
};

export class MySurvey extends Component<State> {
  state: State = {
    bunchModal: false,
    allSurvey: [],
    address: 'null',
    spin: true,
    currentIncentive: '0',
    newIncentive: '0',
  };
  // static contextType = walletContext;
  AddIncentive = async () => {
    if (window.wallet) {
      const A = await window.surveyInstance
        .connect(window.wallet)
        .populateTransaction.announceIncentive(ethers.BigNumber.from(this.state.newIncentive));
      console.log('call : ', A);
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to undo this action!',
        html: `<p>You will not be able to undo this action!</p>
                <h1 style={{fontStyle:"bold"}}> Value : 0 ES </h1>
                <small> To : ${A.to} </small><br/><small> From : ${A.from} ES </small>
                <p> gasFee : ${A?.gasPrice || '0'} </p>`,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'No, cancel!',
        confirmButtonText: 'Yes, do it!',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return window.surveyInstance
            .connect(window.wallet)
            .announceIncentive(this.state.newIncentive)
            .then((res) => {
              res.wait();
              Swal.fire('Good job!', 'You have announce new incentive', 'success');
            })
            .catch(async () => {
              const add = window.wallet.address
                ? window.wallet.address
                : await window.wallet.getAddress();
              const x = new ethers.VoidSigner(add, window.providerESN);
              try {
                const A = await window.surveyInstance
                  .connect(x)
                  .estimateGas.announceIncentive(this.state.newIncentive);
                console.log(A);
              } catch (e) {
                console.log('Error is : ', e);
                Swal.fire('Oops...!', `${e}`, 'error');
              }
            });
        },
      });
    } else {
      Swal.fire('Oops...!', `Please connct to your wallet`, 'error');
    }
  };
  getSurvey = async () => {
    if (window.wallet) {
      var address = window?.wallet?.address
        ? window?.wallet?.address
        : await window?.wallet?.getAddress();
      const x = await window.surveyInstance.Incentives(address);
      const currentIncentive = x.toString();
      await this.setState({ ...this.state, address, currentIncentive });
      const filter = window.surveyInstance.filters.NewSurvey(this.state.address, null);
      const logs = await window.surveyInstance.queryFilter(filter);
      const parseLogs = logs.map((log) => window.surveyInstance.interface.parseLog(log));
      const surveyAll = parseLogs.map((ele) => ele.args[1]);
      console.log('All :', surveyAll);
      const detailsAll = await Promise.all(
        surveyAll.map(async (ele) => {
          const x = await window.surveyInstance.surveys(ele);
          const p = [x[0], x[1], x[2], x[3], x[4], ele];
          return p;
        })
      );
      this.setState({ ...this.state, allSurvey: detailsAll });
      console.log('All :', detailsAll);
      return surveyAll;
    }
  };

  handleClose = () => {
    this.setState({
      bunchModal: false,
    });
  };
  //import walletContext
  //   static contextType = WalletContext;
  //    this.context?.state
  // this.context.setState({})

  componentDidMount = async () => {
    await this.getSurvey();
    this.setState({ ...this.state, spin: false });
  };

  render() {
    return (
      <>
        {this.state.spin ? (
          <div className="preloader">
            <Spinner className="loader" animation="border" variant="primary" />
          </div>
        ) : (
          <div className="homebg">
            <div className="banner">
              <div className="wrapper-container">
                <div className="container">
                  <div className="row  align-items-center justify-content-center text-center">
                    <div className="col-lg-10 align-self-end">
                      <h1 className="text-uppercase text-white font-weight-bold">CERTIÐAPP</h1>
                      <div className="divider my-4"></div>
                    </div>
                    <div className="col-lg-8 align-self-baseline">
                      <h4 className="mrg-home">
                        Authenticate Digital Certificates, <br />
                        Powered by:BlockchainTechnology
                      </h4>

                      <a
                        // eslint-disable-next-line react/jsx-no-target-blank
                        href="https://eraswap.life/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-primary btn-xl js-scroll-trigger combtn mt30 mb30"
                      >
                        Connect To Wallet
                      </a>

                      <button
                        className="btn btn-primary btn-xl js-scroll-trigger combtn mt30 mb30"
                        onClick={this.getSurvey}
                      >
                        Metamask
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              {/* <div className="row mt30 mb30">
                <div className="four col-md-3">
                  <div className="counter-box ">
                    {' '}
                    <i className="fa fa-thumbs-o-up"></i> <span className="counter">2147</span>
                    <p>Open </p>
                  </div>
                </div>
                <div className="four col-md-3">
                  <div className="counter-box">
                    {' '}
                    <i className="fa fa-file"></i> <span className="counter">3275</span>
                    <p>Draft </p>
                  </div>
                </div>
                <div className="four col-md-3">
                  <div className="counter-box">
                    {' '}
                    <i className="fa fa-reply"></i> <span className="counter">289</span>
                    <p>Total responses</p>
                  </div>
                </div>
                <div className="four col-md-3">
                  <div className="counter-box">
                    {' '}
                    <i className="fa fa-percent"></i> <span className="counter">1563</span>
                    <p>Average competition rate</p>
                  </div>
                </div>
              </div> */}

              <div className="rows text-left mt30 mb10">
                <div className="header-title">
                  <h3>
                    Recent Survey <i className="fa fa-refresh" onClick={this.getSurvey}></i>{' '}
                  </h3>
                </div>
                <div className="row">
                  {this.state.allSurvey.map((ele) => {
                    return (
                      <div className="col-md-3 col-sm-3 col-xs-12 card-main-section">
                        <div className="row card-part">
                          <Timer time={ele[3]} />
                          <div className="col-md-12 col-sm-12 col-xs-12 card-title text-left">
                            <h1>{ele[1]}</h1>
                          </div>
                          <div className="col-md-12 col-sm-12 col-xs-12 card-cart text-center">
                            <Link
                              to={'/Surveys/' + ele[5]}
                              className="btn btn-primary com-btn btn-block"
                            >
                              Start Survey
                            </Link>
                            <Link
                              to={'/Result/' + ele[5]}
                              className="btn btn-primary com-btn btn-block"
                            >
                              See Results
                            </Link>
                            <div className="small bold text-truncate">{ele[2]}</div>
                            {ele[4] ? null : (
                              <div className="badge badge-pill badge-primary">Private Survey</div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}{' '}
                </div>
                <br />
                <br />
                <div className="header-title">
                  <h3>Announce Incentive </h3>
                </div>
                <div className="form-group row mx-auto">
                  <div>You can Announce extra incentive. (default Incentive is set to 1%) </div>
                  <div>
                    {' '}
                    <br />
                    <b>Your current extra incentive is : </b>
                    {this.state.currentIncentive} %{' '}
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <input
                    className="form-control col-6"
                    type="number"
                    onChange={(e) => this.setState({ ...this.state, newIncentive: e.target.value })}
                    name="incentives"
                  />
                  <button className="col-2  btn-primary" onClick={this.AddIncentive}>
                    Announce
                  </button>
                </div>
              </div>
            </div>

            {/* <div className="container main-section">
              <div className="row mt30 mb30">
                <div className="col-md-4 col-sm-4 col-xs-12 ">
                  <div className="rows text-left  mb10">
                    <div className="header-title">
                      <h3>Your Profile  </h3>
                    </div>
                  </div>
                  <div className="card-main-section">
                    <div className=" card-part">
                      <div className=" card-body text-left">
                        <ul className="list-group ">
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            Name
                            <span className="badge badge-primary badge-pill"> Eraswap Team</span>
                          </li>
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            Instituion
                            <span className="badge badge-primary badge-pill">Mumbai</span>
                          </li>
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            Mail Id
                            <span className="badge badge-primary badge-pill">suopport@eraswap.com</span>
                          </li>
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                           Incentices
                            <span className="badge badge-primary badge-pill">0</span>
                          </li>
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                           Announce Incentives
                            <span className="badge badge-primary badge-pill">0</span>
                          </li>
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                           
                          <div className="form-group row">
                            <input className="form-control col-10" onChange={(e)=>this.setState({ ...this.state, newIncentive: e.target.value })} name="incentives" />
                            <button className="col-2" >Add</button>
                          </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4 col-xs-12 ">
                  <div className="rows text-left  mb10">
                    <div className="header-title">
                      <h3>Survey Pole</h3>
                    </div>
                  </div>
                  <div className="card-main-section">
                    <div className=" card-part">
                      <div className=" card-body text-left">
                        <div className=" pinside40">
                          <h6 className="fill-head">
                            How do rate your overall satisfaction about the service provided?
                          </h6>

                          <div>
                            <input type="radio" id="2" name="2" value="1" className="radio-btn" />
                            <label className="serv-txt">Poor</label>
                          </div>

                          <div>
                            <input type="radio" id="2" name="2" value="2" className="radio-btn" />
                            <label className="serv-txt">Fair</label>
                          </div>

                          <div>
                            <input type="radio" id="3" name="3" value="3" className="radio-btn" />
                            <label className="serv-txt">Good</label>
                          </div>

                          <div>
                            <input type="radio" id="4" name="4" value="4" className="radio-btn" />
                            <label className="serv-txt">Great</label>
                          </div>

                          <div>
                            <input type="radio" id="5" name="5" value="5" className="radio-btn" />
                            <label className="serv-txt">Excellent</label>
                          </div>
                        </div>

                        <p>
                          <small>
                            {' '}
                            What is this? We’re generally curious about all sorts of topics and like
                            to ask questions to gather data. Don’t worry, your personal data will
                            never be shared. Privacy Policy »
                          </small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4 col-xs-12 ">
                  <div className="rows text-left  mb10">
                    <div className="header-title">
                      <h3>Other platform by EraSwap</h3>
                    </div>
                  </div>
                  <div className="card-main-section">
                    <div className=" card-part">
                      <div className=" card-body text-left">
                        <ul className="list-group ">
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            Name
                            <span className="badge badge-primary badge-pill"> -</span>
                          </li>
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            organization, firm
                            <span className="badge badge-primary badge-pill">-</span>
                          </li>
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            Mail Id
                            <span className="badge badge-primary badge-pill">-</span>
                          </li>
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            Postoftheperson
                            <span className="badge badge-primary badge-pill">-</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        )}
        <Footer />
      </>
    );
  }
}
