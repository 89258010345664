import React, { useState } from 'react';
import { Card, Button, Spinner } from 'react-bootstrap';
import { useMapState } from '../MapState';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { renderTimeStamp } from '../../utils';
import { ethers } from 'ethers';
import ipfs from '../ipfs';

export default function Result({ address }) {
  const { mapState } = useMapState();
  const [isSubmit, setisSubmit] = useState<boolean>(false);
  // const [txn,setTxn] = useState({hash:' ',timestamp : 0});
  const [spin, setSpin] = useState<boolean>(false);
  const [done, setDone] = useState<boolean>(false);
  const [hash, setHash] = useState('loading...');
  const [byte, setByte] = useState('loading...');

  const loadIPFS = async () => {
    let asyncGenerator = await ipfs.add(JSON.stringify(mapState));
    console.log(asyncGenerator);
    return asyncGenerator.path;
    // await get(asyncGenerator.path);
    // console.log('done');
  };
  var str2bool = (value) => {
    if (value && typeof value === 'string') {
      if (value.toLowerCase() === 'true') return true;
      if (value.toLowerCase() === 'false') return false;
    }
    return value;
  };
  const handleSubmit = async () => {
    setisSubmit(true);
    setSpin(true);
    const Hash = await loadIPFS();
    const val = str2bool(mapState.isPublic) ? '100' : '10';
    console.log(Hash);
    if (window.wallet) {
      try {
        const sur = await window.surveyInstance
          .connect(window.wallet)
          .addSurvey(
            Hash,
            mapState.Title,
            renderTimeStamp(mapState.time),
            str2bool(mapState.isPublic),
            {
              value: ethers.utils.parseEther(val),
            }
          );
        const receipt = await sur.wait();
        const event = window.surveyInstance.interface.parseLog(receipt.logs[0]);
        // const parseLogs = receipt.logs.map((log) => window.surveyInstance.interface.parseLog(log));
        console.log(event.args[1]);
        setByte(event.args[1]);
        console.log('TXN Hash :', sur);
        setHash(sur.hash);
        setDone(true);
      } catch (e) {
        let add = await window.wallet.getAddress();
        if (!add) add = window.wallet.address;
        const x = new ethers.VoidSigner(add, window.providerESN);
        try {
          const A = await window.surveyInstance
            .connect(x)
            .estimateGas.addSurvey(
              Hash,
              mapState.Title,
              renderTimeStamp(mapState.time),
              str2bool(mapState.isPublic),
              {
                value: ethers.utils.parseEther(val),
              }
            );
          console.log(A);
        } catch (e) {
          console.log('Error is : ', e);

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${e}`,
          });
          console.log(e);
        }
        setisSubmit(false);
      }
    }
    setSpin(false);
  };

  const TxnDetails = ({ hash, byte }) => {
    return (
      <>
        <br />
        <i className="fa fa-check-circle" style={{ fontSize: '8em', color: '#54BE3D' }}></i>
        <p>
          {' '}
          Transaction Sussessful <br /> Your Survey has been created{' '}
        </p>
        <br /> <br /> <br />
        <table className="table">
          <tr>
            <td> Transaction Hash : </td>
            <td>
              <a href={'https://eraswap.info/txn/' + hash}>{hash} </a>{' '}
            </td>
          </tr>
          <tr>
            <td>TimeStamp</td>
            <td>{Date.now()}</td>
          </tr>
          <tr>
            <td>Your Survey </td>
            <td>
              <Link to={'/Surveys/' + byte}>{byte}</Link>
            </td>
          </tr>
          <hr />
        </table>
      </>
    );
  };

  return (
    <div>
      <br />
      <br />
      <Card>
        <Card.Body>
          <Card.Title>Complete your Survey</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">Are you Sure</Card.Subtitle>
          <Card.Text>
            you have to pay {str2bool(mapState.isPublic) ? '100 ES ' : ' 10 ES '}{' '}
          </Card.Text>
          {isSubmit ? null : (
            <>
              <Card.Link className="mr-5" href="./home">
                No
              </Card.Link>
              <Button variant="primary" onClick={handleSubmit}>
                Yes
              </Button>
            </>
          )}
          <br />
          <br />
          {spin ? (
            <>
              <Spinner animation="border" variant="primary" />
              <Card.Text>Please wait ...</Card.Text>
            </>
          ) : null}
          {done ? (
            <>
              <TxnDetails hash={hash} byte={byte} />
            </>
          ) : (
            <Button variant="primary" onClick={handleSubmit}>
              {' '}
              Retry{' '}
            </Button>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}
