import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Footer } from '../Footer';
import { Timer } from '../Timer';

export default function All() {
  const [allSurvey, setAllSurvey] = useState([]);
  const [endedSurvey, setEndedSurvey] = useState([]);

  const getSurvey = async () => {
    const filter = window.surveyInstance.filters.NewSurvey(null, null);
    const logs = await window.surveyInstance.queryFilter(filter);
    const parseLogs = logs.map((log) => window.surveyInstance.interface.parseLog(log));
    const surveyAll = parseLogs.map((ele) => ele.args[1]);
    const detailsAll = await Promise.all(
      surveyAll.map(async (ele) => {
        const x = await window.surveyInstance.surveys(ele);
        const incentive = await window.surveyInstance.Incentives(x[2]);
        const p = [x[0], x[1], x[2], x[3], x[4], ele, incentive];
        return p;
      })
    );
    const now: number = Math.floor(Date.now() / 1000);
    const newSurvey = detailsAll.filter((e) => e[3] > now);
    const end = detailsAll.filter((e) => e[3] < now);
    end.reverse();
    newSurvey.reverse();
    console.log('before time :', newSurvey);
    console.log('All :', detailsAll);
    setAllSurvey(newSurvey);
    setEndedSurvey(end);
    return surveyAll;
  };

  useEffect(() => {
    (async () => {
      await getSurvey();
    })();
  });

  // const searchbox = {
  //   border : "none",
  //   borderBottom : "2px solid #005099",
  //   outline : "none",
  //   marginBottom : '5px'
  // }
  return (
    <div className="homebg">
      <div className="banner" style={{ height: '50%' }}>
        <div className="wrapper-container">
          <div className="container">
            {/* <Form  style={{ maxWidth: '800px' }} className="mx-auto">
              <Form.Group>
                <Form.Control  style={searchbox} className="w-100 text-light" type="text" placeholder="Search here ..." />
                <Button type="submit" className="mb-2">
                  <i className="fa fa-search" aria-hidden="true"></i> Search
                </Button>
              </Form.Group>
            </Form> */}
            <div className="col-lg-10 align-self-end">
              <h1 className="text-uppercase text-white font-weight-bold">Welcome here</h1>

              <div className="divider my-4"></div>
            </div>
            <h4 className="mrg-home">
              Conduct online multi-purpose blockchain surveys like Customer satisfaction survey,
              employee satisfaction survey, Market research survey, Brand awareness survey, and many
              more.
            </h4>{' '}
            <br />
            <br />
            <br />
            <br />
          </div>{' '}
        </div>{' '}
      </div>
      <div className="container">
        <div className="rows text-left mt30 mb10">
          <div className="header-title">
            <h3>Public Survey</h3>
          </div>
        </div>
        <div className="row">
          {allSurvey.map((ele) => {
            return (
              <div className="col-md-3 col-sm-3 col-xs-12 card-main-section my-1">
                <div className="row card-part">
                  <Timer time={ele[3]} />
                  <div className="col-md-12 col-sm-12 col-xs-12 card-title text-left">
                    <h1>{ele[1]}</h1>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xs-12 card-cart text-center">
                    <Link to={'/Surveys/' + ele[5]} className="btn btn-primary com-btn btn-block">
                      Start Survey
                    </Link>
                    <div className="small bold text-truncate">{ele[2]}</div>
                    <div className="small bold text-truncate">
                      Incentive : {parseInt(ele[6]) + 1} %
                    </div>
                    {ele[4] ? null : (
                      <div className="badge badge-pill badge-primary">Private Survey</div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}{' '}
        </div>

        <div className="rows text-left mt30 mb10">
          <div className="header-title">
            <h3>Ended Survey</h3>
          </div>
        </div>
        <div className="row">
          {endedSurvey.map((ele) => {
            return (
              <div className="col-md-3 col-sm-3 col-xs-12 card-main-section my-1">
                <div className="row card-part">
                  <Timer time={ele[3]} />
                  <div className="col-md-12 col-sm-12 col-xs-12 card-title text-left">
                    <h1>{ele[1]}</h1>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xs-12 card-cart text-center">
                    <Link to={'/Result/' + ele[5]} className="btn btn-primary com-btn btn-block">
                      See Result
                    </Link>
                    <div className="small bold text-truncate">{ele[2]}</div>
                    <div className="small bold text-truncate">
                      Incentive : {parseInt(ele[6]) + 1} %
                    </div>
                    {ele[4] ? null : (
                      <div className="badge badge-pill badge-primary">Private Survey</div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}{' '}
        </div>
      </div>
      <Footer />
    </div>
  );
}
