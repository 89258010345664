import React, { useState, useEffect } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { Footer } from './Footer';
import Swal from 'sweetalert2';
import { ethers } from 'ethers';
import { Timer } from './Timer';
// import { Transaction } from './Transaction';

interface Q {
  question: string;
  options: string[];
}
type SurveyData = {
  Title?: string;
  description?: string;
  time?: string;
  isPublic?: boolean;
  question: Q[];
};

type CardProps = {
  index: number;
  name: string;
  option: string[];
  handleChange: any;
};

const initialState: SurveyData = {
  Title: '',
  description: '',
  time: '00:00',
  isPublic: false,
  question: [{ question: '', options: [] }],
};
function Question(props: CardProps) {
  return (
    <div
      style={{ borderLeft: '5px solid #4285F4' }}
      className="text-left bg-white my-3 p-5 rounded"
    >
      <h6>
        <span className="badge badge-pill badge-primary mr-2">Q : {props.index + 1}</span>
        {props.name}
      </h6>
      <Form.Group>
        {/* {props.option} */}
        {props.option.map((ele, i) => (
          <Form.Check
            className="ml-5 font-weight-lighter"
            required
            type="radio"
            label={ele}
            name={props.name}
            value={i}
            onChange={(e) => props.handleChange({ [e.target.name]: e.target.value })}
          />
        ))}
      </Form.Group>
    </div>
  );
}

function Surveys() {
  const { SurveyHash } = useParams() as { SurveyHash: string };
  const [exist, setExist] = useState<boolean>(true);
  const [spin, setSpin] = useState<boolean>(true);
  const [ans, setAns] = useState<object>({});
  const [newSurvey, setNewSurvey] = useState<SurveyData>(initialState);
  const [timeLeft, setTimeLeft] = useState<number>(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getData = async () => {
    const hash = await window.surveyInstance.surveys(SurveyHash);
    console.log('Remix : ', hash);
    const ipfshash = hash[0];
    setTimeLeft(hash[3].toNumber());
    // const data = await axios.get(`https://ipfs.infura.io/ipfs/${ipfshash}`);
    const data = await axios.get(`https://ipfs.eraswap.cloud/ipfs/${ipfshash}`);
    console.log(data.data);
    //  if(data.status !== 200)setExist(false);
    setNewSurvey(data.data);
  };
  const handleChange = (ele: object) => {
    setAns({
      ...ans,
      ...ele,
    });
  };

  const Submit = async () => {
    const answer = [];
    for (let i in ans) {
      answer.push(ans[i]);
    }
    console.log(answer);
    const A = await window.surveyInstance
      .connect(window.wallet)
      .populateTransaction.sendSurvey(SurveyHash, answer, {
        value: ethers.utils.parseEther('1'),
      });
    console.log('call : ', A);
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to undo this action!',
      html: `<p>You will not be able to undo this action!</p>
              <h1 style={{fontStyle:"bold"}}> Value : ${ethers.utils.formatEther(A.value)} </h1>
              <small> To : ${A.to} </small><br/><small> From : ${A.from} ES </small>
              <p> gasFee : ${A?.gasPrice || '0'} </p>`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No, cancel!',
      confirmButtonText: 'Yes, do it!',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return window.surveyInstance
          .connect(window.wallet)
          .sendSurvey(SurveyHash, answer, {
            value: ethers.utils.parseEther('1'),
          })
          .then((res) => {
            res.wait();
            Swal.fire('Good job!', '"You have Submit your Survey', 'success');
          })
          .catch(async () => {
            const add = window.wallet.address
              ? window.wallet.address
              : await window.wallet.getAddress();
            const x = new ethers.VoidSigner(add, window.providerESN);
            try {
              const A = await window.surveyInstance
                .connect(x)
                .estimateGas.sendSurvey(SurveyHash, answer);
              console.log(A);
            } catch (e) {
              console.log('Error is : ', e);
              Swal.fire('Oops...!', `${e}`, 'error');
            }
          });
      },
    });
  };
  // alert("You have Submit your Survey");
  // Swal.fire('Good job!', '"You have Submit your Survey', 'success');

  const handleSubmit = (e) => {
    e.preventDefault();
    Submit();
    const answer = [];
    for (let i in ans) {
      answer.push(ans[i]);
    }
    console.log(answer);

    // Transaction(async () => window.surveyInstance.connect(window.wallet).sendSurvey(SurveyHash,answer,{
    //   value: ethers.utils.parseEther('1'),
    // }) );
  };
  useEffect(() => {
    (async () => {
      try {
        await getData();
      } catch {
        setExist(false);
      }
      setSpin(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spin]);

  return (
    <div className="bg-light">
      {spin ? (
        <div className="preloader">
          <Spinner className="loader" animation="border" variant="primary" />
        </div>
      ) : null}
      {exist ? (
        <>
          {/* <LandingPageHeader /> */}
          <div className="banner" style={{ height: '50%' }}>
            <div className="wrapper-container">
              <div className="container">
                <div className="row  align-items-center justify-content-center text-center">
                  <div className=" " style={{ textAlign: 'center' }}>
                    <h1 className="text-uppercase text-white font-weight-bold">CERTIÐAPP</h1>
                    <div className="divider my-4"></div>
                  </div>
                  <div className="col-lg-8 align-self-baseline">
                    <h4 className="mrg-home">
                      Authenticate Digital Certificates, <br />
                      Powered by:BlockchainTechnology
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Timer time={timeLeft} />
          <div style={{ maxWidth: '800px' }} className="mx-auto">
            <div
              style={{ borderLeft: '5px solid #4285F4' }}
              className="text-left bg-white p-5 rounded"
            >
              <h3 className="text-dark"> {newSurvey.Title} </h3>
              <hr />
              <ReactMarkdown source={newSurvey.description} escapeHtml={false} />
            </div>
            <Form onSubmit={handleSubmit}>
              {newSurvey.question.map((ele, index) => (
                <Question
                  handleChange={handleChange}
                  name={ele.question}
                  index={index}
                  option={ele.options}
                />
              ))}
              <button className="btn btn-primary">Submit </button>
            </Form>
          </div>
          <br /> <br /> <br /> <br />
          <Footer />
        </>
      ) : (
        <div className="error"> </div>
      )}
    </div>
  );
}
export default Surveys;
