import React, { useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import { Home } from './components/Home/Home';
import { Survey } from './components/Survey/Survey';
import { CreateSurvey } from './components/CreateSurvey/CreateSurvey';
import { MySurvey } from './components/MySurvey/MySurvey';
import Surveys from './components/Surveys';
import Result from './components/Result';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NewHeader from './components/Headers/NewHeader';
import WalletContext from './components/WalletContext';
import { addresses } from 'eraswap-sdk';
import All from './components/All/All';
function App() {
  const [state, setState] = useState();
  console.log(addresses['production']);
  console.log(window.providerESN?.resolveAddress(addresses['production'].ESN.buildSurvey));

  //  const location = useLocation()
  return (
    <div className="App">
      <WalletContext.Provider value={{ state, setState }}>
        <BrowserRouter>
          <NewHeader />
          <Switch>
            <Route path="/" exact component={Survey} />
            <Route path="/All" exact component={All} />
            <Route path="/MySurvey" exact component={MySurvey} />
            <Route path="/Surveys/:SurveyHash" exact component={Surveys} />
            <Route path="/Result/:SurveyHash" exact component={Result} />
            <Route path="/CreateSurvey" exact component={CreateSurvey} />
          </Switch>
          {/* <Footer/> */}
        </BrowserRouter>
      </WalletContext.Provider>
    </div>
  );
}

export default App;
