import React, { Component } from 'react';
import './Survey.css';
import ethers from 'ethers';
import { Link } from 'react-router-dom';
import { Footer } from '../Footer';
import { Timer } from '../Timer';
import 'bootstrap/dist/css/bootstrap.min.css';

type State = {
  address: string;
  allSurvey: any[];
  mySurvey: any[];
};

export class Survey extends Component<State> {
  state: State = {
    address: '',
    allSurvey: [],
    mySurvey: [],
  };
  // static contextType = walletContext;
  getSurvey = async () => {
    const filter = window.surveyInstance.filters.NewSurvey(null, null);
    const logs = await window.surveyInstance.queryFilter(filter);
    const parseLogs = logs.map((log) => window.surveyInstance.interface.parseLog(log));
    const surveyAll = parseLogs.map((ele) => ele.args[1]);
    const detailsAll = await Promise.all(
      surveyAll.map(async (ele) => {
        const x = await window.surveyInstance.surveys(ele);
        const incentive = await window.surveyInstance.Incentives(x[2]);
        const p = [x[0], x[1], x[2], x[3], x[4], ele, incentive];
        return p;
      })
    );
    // const now = renderTimeStamp();
    // detailsAll.filter()
    const now: number = Math.floor(Date.now() / 1000);
    const newSurvey = detailsAll.filter((e) => e[3] > now);
    newSurvey.reverse();
    console.log('All :', newSurvey);
    this.setState({ ...this.state, allSurvey: newSurvey });
    return surveyAll;
  };

  privateSurvey = async () => {
    const myAddress = window?.wallet?.address
      ? window?.wallet?.address
      : await window?.wallet?.getAddress();
    const filter = window.surveyInstance.filters.Auth1(myAddress, null);
    const logs = await window.surveyInstance.queryFilter(filter);
    const parseLogs = logs.map((log) => window.surveyInstance.interface.parseLog(log));
    const surveyAll = parseLogs.map((ele) => ele.args[1]);
    const detailsAll = await Promise.all(
      surveyAll.map(async (ele) => {
        const x = await window.surveyInstance.surveys(ele);
        const incentive = await window.surveyInstance.Incentives(x[2]);
        const p = [x[0], x[1], x[2], x[3], x[4], ele, incentive];
        return p;
      })
    );
    // const now = renderTimeStamp();
    // detailsAll.filter()
    const now: number = Math.floor(Date.now() / 1000);
    const newSurvey = detailsAll.filter((e) => e[3] > now);
    newSurvey.reverse();

    console.log('All :', newSurvey);
    this.setState({ ...this.state, mySurvey: newSurvey });
    return surveyAll;
  };

  loadEvents = async () => {
    const filter = window.surveyInstance.filters.NewSurvey(null, null);
    const logs = await window.surveyInstance.queryFilter(filter);
    const parsedLogs = logs.map((log) => window.surveyInstance.interface.parseLog(log));
    const updatevalues = parsedLogs.map((parsedLogs) => parsedLogs.args[0] as ethers.BigNumber);
    console.log('getvaluue: ', updatevalues);
  };
  componentDidMount = async () => {
    if (window.wallet) {
      if (window.wallet.address) this.setState({ ...this.state, address: window.wallet.address });
      else this.setState({ ...this.state, address: await window.wallet.getAddress() });
    }
    await this.getSurvey();
    await this.privateSurvey();
  };

  render() {
    return (
      <div className="homebg">
        {/* <NewHeader/> */}
        <div className="banner">
          <div className="wrapper-container">
            <div className="container">
              <div className="row  align-items-center justify-content-center text-center">
                <div className="col-lg-10 align-self-end">
                  <h1 className="text-uppercase text-white font-weight-bold">Welcome here</h1>

                  <div className="divider my-4"></div>
                </div>
                <div className="col-lg-8 align-self-baseline">
                  <h4 className="mrg-home">
                    Conduct online multi-purpose blockchain surveys like Customer satisfaction
                    survey, employee satisfaction survey, Market research survey, Brand awareness
                    survey, and many more.
                  </h4>
                  <h5 className="text-warning">Powered by Era Swap DAO</h5>

                  <Link to="/CreateSurvey" className="btn btn-primary  js-scroll-trigger">
                    Create a Survey
                  </Link>

                  <Link to="/all" className="btn btn-primary  js-scroll-trigger">
                    Participate in a Survey
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container main-section">
          <div className="rows text-left mt30 mb10">
            <div className="header-title">
              <h3>Public Survey</h3>
            </div>
          </div>
          <div className="row">
            {this.state.allSurvey.map((ele) => {
              return (
                <div className="col-md-3 col-sm-3 col-xs-12 card-main-section">
                  <div className="row card-part">
                    <Timer time={ele[3]} />
                    <div className="col-md-12 col-sm-12 col-xs-12 card-title text-left">
                      <h1>{ele[1]}</h1>
                    </div>
                    <div className="col-md-12 col-sm-12 col-xs-12 card-cart text-center">
                      <Link to={'/Surveys/' + ele[5]} className="btn btn-primary com-btn btn-block">
                        Start Survey
                      </Link>
                      <div className="small bold text-truncate">{ele[2]}</div>
                      <div className="small bold text-truncate">
                        Incentive : {parseInt(ele[6]) + 1} %
                      </div>
                      {ele[4] ? null : (
                        <div className="badge badge-pill badge-primary">Private Survey</div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}{' '}
            <div className="col-md-12 text-right">
              <Link to="" className="text-right text-black">
                See All
              </Link>
            </div>
          </div>
        </div>

        <div className="container main-section">
          <div className="rows text-left  mb10">
            <div className="header-title">
              <h3>
                Authorized Survey <i className="fa fa-refresh" onClick={this.privateSurvey}></i>
              </h3>
            </div>
          </div>
          <div className="row">
            {window.wallet
              ? this.state.mySurvey.map((ele) => {
                  return (
                    <div className="col-md-3 col-sm-3 col-xs-12 card-main-section">
                      <div className="row card-part">
                        <Timer time={ele[3]} />
                        <div className="col-md-12 col-sm-12 col-xs-12 card-title text-left">
                          <h1>{ele[1]}</h1>
                        </div>
                        <div className="col-md-12 col-sm-12 col-xs-12 card-cart text-center">
                          <Link
                            to={'/Surveys/' + ele[5]}
                            className="btn btn-primary com-btn btn-block"
                          >
                            Start Survey
                          </Link>
                          <div className="small bold text-truncate">{ele[2]}</div>
                          <div className="small bold text-truncate">
                            Incentive : {parseInt(ele[6]) + 1} %
                          </div>
                          {ele[4] ? null : (
                            <div className="badge badge-pill badge-primary">Private Survey</div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              : 'Please connect to wallet to see survey'}{' '}
            <div className="col-md-12 text-right">
              <Link to="" className="text-right text-black">
                See All
              </Link>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
