import React, { useState, useEffect } from 'react';
import { Spinner, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { Footer } from './Footer';
import { HorizontalBar } from 'react-chartjs-2';
import { ethers } from 'ethers';
import Swal from 'sweetalert2';

interface Q {
  question: string;
  options: string[];
}
type SurveyData = {
  Title?: string;
  description?: string;
  time?: string;
  isPublic?: boolean;
  question: Q[];
};

var DeclareResult: Array<number[]> = [];
const initialState: SurveyData = {
  Title: '',
  description: '',
  time: '00:00',
  isPublic: false,
  question: [{ question: '', options: [''] }],
};

var totalResponse = 0;
const getResult = async (id) => {
  const filter = window.surveyInstance.filters.SentSurvey(id, null);
  const logs = await window.surveyInstance.queryFilter(filter);
  const parseLogs = logs.map((log) => window.surveyInstance.interface.parseLog(log));
  const surveyAll = parseLogs.map((ele) => ele.args[1]);
  console.log('Result :', surveyAll);
  totalResponse = surveyAll.length;
  for (const i of surveyAll) {
    for (const j in i) {
      DeclareResult[j][i[j]] = DeclareResult[j][i[j]] + 1;
    }
  }
  console.log(DeclareResult);
  return surveyAll;
};

function Result() {
  const { SurveyHash } = useParams() as { SurveyHash: string };
  const [exist, setExist] = useState<boolean>(true);
  const [spin, setSpin] = useState<boolean>(true);
  const [newSurvey, setNewSurvey] = useState<SurveyData>(initialState);
  const [user, setUser] = useState([]);
  const [temp, setTemp] = useState('');
  const [add, setAdd] = useState(false);
  const [isprivate, SetIsprivate] = useState<Boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getData = async () => {
    console.log(add);

    const hash = await window.surveyInstance.surveys(SurveyHash);
    console.log('Remix : ', hash);
    const ipfshash = hash[0];
    SetIsprivate(!hash[4]);
    const data = await axios.get(`https://ipfs.eraswap.cloud/ipfs/${ipfshash}`);
    // const data = await axios.get(`https://ipfs.infura.io/ipfs/${ipfshash}`);
    console.log(data.data);
    //  if(data.status !== 200)setExist(false);
    setNewSurvey(data.data);
    const graph: SurveyData = data.data;
    console.log(graph);
    for (const i of graph.question) {
      let n = i.options.length;
      console.log('value', i.options.length);
      const x = new Array(n).fill(0);
      DeclareResult.push(x);
    }
    console.log(DeclareResult);
  };
  const addUser = async () => {
    console.log(user);
    let newUsers = user.filter(ele => ele !== "");
    let uniqueUser = newUsers.filter((value,index) => newUsers.indexOf(value) === index);
    setUser([...uniqueUser]);
    
    if (window.wallet) {
      const sur = await window.surveyInstance
        .connect(window.wallet)
        .populateTransaction.addUsers(SurveyHash, user, {
          value: ethers.utils.parseEther(String(user.length)),
        });
      console.log('TXN Hash :', sur);

      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to undo this action!',
        html: `<p>You will not be able to undo this action!</p>
                    <h1 style={{fontStyle:"bold"}}> Value : ${ethers.utils.formatEther(
                      sur.value
                    )} </h1>
                    <small> To : ${sur.to} </small><br/><small> From : ${sur.from} ES </small>
                    <p> gasFee : ${sur?.gasPrice || '0'} </p>`,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'No, cancel!',
        confirmButtonText: 'Yes, do it!',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return window.surveyInstance
            .connect(window.wallet)
            .addUsers(SurveyHash, user, {
              value: ethers.utils.parseEther(String(user.length)),
            })
            .then((res) => {
              res.wait();
              Swal.fire('Good job!', 'You have add participants', 'success');
            })
            .catch(async () => {
              const add = window.wallet.address
                ? window.wallet.address
                : await window.wallet.getAddress();
              const x = new ethers.VoidSigner(add, window.providerESN);
              try {
                const A = await window.surveyInstance
                  .connect(x)
                  .estimateGas.addUsers(SurveyHash, user, {
                    value: ethers.utils.parseEther(String(user.length)),
                  });
                console.log(A);
              } catch (e) {
                console.log('Error is : ', e);
                Swal.fire('Oops...!', `${e}`, 'error');
              }
            });
        },
      });
      //   Swal.fire({
      //     icon: 'success',
      //     title: 'Oops...',
      //     text: 'You have added users',
      //   });
      // } catch (e) {
      //   let add = await window.wallet.getAddress();
      // if(!add)add = window.wallet.address;
      // const x = new ethers.VoidSigner(add, window.providerESN);
      // try {
      //   const A = await window.surveyInstance.connect(x).estimateGas.addUsers(SurveyHash,user, {
      //     value: ethers.utils.parseEther(String(user.length)),
      //   });
      //   console.log(A);
      // } catch (e) {
      //   console.log('Error is : ', e);
      //   Swal.fire({
      //     icon: 'error',
      //     title: 'Oops...',
      //     text: `${e}`,
      //   });
      //   // setisSubmit(false);
      // }}
    } else
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please Connect to wallet!',
      });
  };
  const handleChange = (e) => {
    if (temp.length === 42) {
      setAdd(true);
    } else {
      setAdd(false);
    }
    setTemp(e.target.value);
  };
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new window.FileReader();
    reader.readAsText(file);
    // var myString = reader.toString();
    reader.onload = function (e) {
      var text = e.target.result;
      // var data = $.csv.toArrays(text);
      console.log(text);
      const User = text
        .toString()
        .split('\n')
        .map((e) => {
          const row = e.split(',');
          return row[0];
        });
      setUser([...user, ...User]);
    };
    // console.log(myString);
  };
  const addAddress = () => {
    setUser([...user, temp]);
    setTemp('');
  };
  useEffect(() => {
    (async () => {
      try {
        await getData();
        await getResult(SurveyHash);
      } catch {
        setExist(false);
      }
      setSpin(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spin]);

  return (
    <div className="bg-light">
      {spin ? (
        <div className="preloader">
          <Spinner className="loader" animation="border" variant="primary" />
        </div>
      ) : null}
      {exist ? (
        <>
          <div className="banner" style={{ height: '50%' }}>
            <div className="wrapper-container">
              <div className="container">
                <div className="row  align-items-center justify-content-center text-center">
                  <div className=" " style={{ textAlign: 'center' }}>
                    <h1 className="text-uppercase text-white font-weight-bold">CERTIÐAPP</h1>
                    <div className="divider my-4"></div>
                  </div>
                  <div className="col-lg-8 align-self-baseline">
                    <h4 className="mrg-home">
                      Authenticate Digital Certificates, <br />
                      Powered by:BlockchainTechnology
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>{' '}
          <br />
          <br />
          {isprivate ? (
            <div style={{ maxWidth: '800px' }} className="mx-auto">
              <div
                style={{ borderLeft: '5px solid #4285F4' }}
                className="text-left bg-white p-5 rounded"
              >
                <h3 className="text-dark"> Add people</h3>
                <hr />
                <p>This is private Survey. You have to Add Participants</p>
                <hr />
                <Row>
                  <Col>
                    <input
                      className="btn btn-primary"
                      type="file"
                      accept=".csv"
                      onChange={handleFileUpload}
                    />
                    {/* <button className="btn btn-primary">Add CSV File </button> */}
                    <br />
                    <h4 className="text-center">OR</h4> <br />
                    <div className="form-group row">
                      <h4>Add CSV file</h4>
                      <input
                        className="form-control col-8 m-3"
                        name="user"
                        value={temp}
                        onChange={handleChange}
                      />
                      <button className="btn btn-primary border bordercircle" onClick={addAddress}>
                        {' '}
                        <i className="fa fa-plus m-0" aria-hidden="true"></i>
                      </button>
                    </div>
                  </Col>
                  <Col>
                    <ul className="list-group list-group-flush " style={{ overflow: 'auto' }}>
                      {user.map((ele) => (
                        <>
                          <li className="list-group-item">{ele}</li>
                        </>
                      ))}
                    </ul>
                    <button className="btn btn-primary" onClick={addUser}>
                      Authorize Above users for this servey
                    </button>
                  </Col>
                </Row>
              </div>
            </div>
          ) : null}
          <br />
          <br />
          <br />
          <br />
          <h1>Total Responses : {totalResponse}</h1>
          <div style={{ maxWidth: '800px' }} className="mx-auto">
            <div
              style={{ borderLeft: '5px solid #4285F4' }}
              className="text-left bg-white p-5 rounded"
            >
              <h3 className="text-dark"> {newSurvey.Title} </h3>
              <hr />
              <ReactMarkdown source={newSurvey.description} escapeHtml={false} />
            </div>
            <div>
              {newSurvey.question.map((ele, index) => (
                <div
                  style={{ borderLeft: '5px solid #4285F4' }}
                  className="text-left bg-white my-3 p-5 rounded"
                >
                  <h6>
                    <span className="badge badge-pill badge-primary mr-2">Q : {index + 1}</span>
                    {ele.question}
                  </h6>
                  <HorizontalBar
                    data={{
                      labels: ele.options,
                      datasets: [
                        {
                          label: 'Responses',
                          backgroundColor: 'rgba(0,80,153,0.8)',
                          borderColor: 'rgb(0,80,153)',
                          borderWidth: 0.5,
                          hoverBackgroundColor: 'rgb(0,80,153)',
                          hoverBorderColor: 'rgb(0,80,153)',
                          data: DeclareResult[index],
                        },
                      ],
                    }}
                  />
                </div>
              ))}
            </div>
            <button className="btn btn-primary">Publish your survey </button>
          </div>
          <br /> <br /> <br /> <br />
          <Footer />
        </>
      ) : (
        <div className="error"> </div>
      )}
    </div>
  );
}
export default Result;
