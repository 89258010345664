import { CustomProvider } from 'eraswap-sdk';
import { SurveyDappFactory } from './SurveyDappFactory';

// const config = addresses['production'];

window.providerESN = new CustomProvider('mainnet');
// window.provider = new CustomProvider('testnet');

window.surveyInstance = SurveyDappFactory.connect(
  // providerESN.resolveAddress(es.addresses[process.env.NODE_ENV].ESN.buildSurvey),
  '0x1F52A1E93f187e9aa8701b98eCd6bFcA4337E6a4',
  window.providerESN
);

